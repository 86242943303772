import React, { useEffect } from "react";
import TwentyThreePosters from "./2023Posters/TwentyThreePosters";
import ActionButtons from "./actionButtons/ActionButtons";
import EventsCaurosel from "./eventsCaurosel/EventsCaurosel";
import TwentyPageHeader from "./twentyPageHeader/TwentyPageHeader";

const TwentyTwoTwentyThree = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      {/* Header */}
      <div>
        <TwentyPageHeader />
      </div>

      {/* Action Buttons */}
      <div className="w-[80%] mx-auto max-w-6xl mt-20">
        <ActionButtons />
      </div>

      {/* Events Caurosel */}
      <div className="mt-20">
        <EventsCaurosel />
      </div>

      <div className="w-[80%] mx-auto max-w-6xl mt-20">
        <TwentyThreePosters />
      </div>
    </div>
  );
};

export default TwentyTwoTwentyThree;
