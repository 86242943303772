import React, { useEffect, useState } from "react";
import styles from "./HypeMessage.module.css";
import Carousel from "react-multi-carousel";
import HypeDefination from "./hypeDefination/HypeDefination";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const hypeDefination = [
  {
    letter: "H",
    title: "HIGH INTENSITY",
    description:
      "Whether performing drills, competing in a tournament, or volunteering at a community shelter, we expect 100% focus and participation from our coaches, athletes, and families.",
  },
  {
    letter: "Y",
    title: "YIELDING MAXIMUM POTENTIAL",
    description:
      "Your capacity to perform at the utmost range of your own ability. Whether on or off the court, we want our athletes to achieve greatness and leave the task at hand knowing they gave it their all.",
  },
  {
    letter: "P",
    title: "PERSEVERANCE / POSITIVITY",
    description:
      "We want our athletes to be willing to push their own limits and positively acknowledge that their efforts will lead to success. By working together to create short- and long-term goals, our coaches and athletes can navigate challenges with a greater sense of self and confidence in our organization.",
  },
  {
    letter: "E",
    title: "EXCELLENCE / ENCOURAGEMENT",
    description:
      "Athletes, and coaches alike, are expected to encourage one another in their pursuit of excellence. While on our journey to achieve greatness, we understand that there is “No loss in lessons learned.”",
  },
];

const HypeMessage = () => {
  const { ref, inView } = useInView();
  const [inViewCarousel, setInViewCarousel] = useState(5);

  const infoAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      infoAnimation.start({
        opacity: 1,
        transition: {
          duration: 1,
        },
      });
    }
  }, [inView]);

  return (
    <div>
      {/* Hype Caurosesl */}
      <div>
        <Carousel
          afterChange={(data) => {
            setInViewCarousel(data);
          }}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
        >
          {hypeDefination.map((data, i) => {
            return (
              <HypeDefination
                key={i}
                letter={data.letter}
                title={data.title}
                description={data.description}
              />
            );
          })}
        </Carousel>
      </div>

      {/* Hype Mission and vision */}
      <div
        ref={ref}
        className={`py-16 px-10 flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 mt-12 ${styles.missionAndVisionContainer}`}
      >
        {/* Our Mission */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={infoAnimation}
          className="w-[95%] mx-auto lg:w-[1/2] hypeMessageBackground"
        >
          {/* Title */}
          <div>
            <h3 className="text-primary text-center lg:text-start text-[1.5rem]">
              OUR MISSION
            </h3>
          </div>

          {/* Text */}
          <div className="mt-2 lg:mt-0">
            <p className={`text-center ${styles.secondaryText}`}>
              Florida HYPE Volleyball is dedicated to
              <span
                className={`${styles.hypeFont} text-primary text-[1.5rem] ml-2`}
              >
                H
              </span>
              elping
              {"  "}
              <span
                className={`${styles.hypeFont} text-primary text-[1.5rem] ml-1`}
              >
                Y
              </span>
              outh{"   "}
              <span
                className={`${styles.hypeFont} text-primary text-[1.5rem] ml-1`}
              >
                P
              </span>
              erform with
              <span
                className={`${styles.hypeFont} text-primary text-[1.5rem] ml-1`}
              >
                E
              </span>
              xcellence in individual endeavors and sports. We pride ourselves
              on offering high-quality coaching that develops strong athletes
              within a unified club culture. Our core values focus on character,
              leadership, and philanthropic involvement within the community. It
              is more than a sport. It is Family.
            </p>
          </div>
        </motion.div>
        {/* Our vision */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={infoAnimation}
          className="w-[95%] mx-auto lg:w-[1/2] hypeMessageBackground"
        >
          {/* Title */}
          <div>
            <h3 className="text-primary text-center lg:text-start text-[1.5rem]">
              OUR VISION
            </h3>
          </div>

          {/* Text */}
          <div className="mt-2 lg:mt-0">
            <p className={`text-center ${styles.secondaryText}`}>
              It is our ultimate vision to support the individual growth of our
              club members, to excel athletically, and grow as servant leaders
              within our community.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HypeMessage;
