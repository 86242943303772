import React, { useEffect, useState } from "react";
import BoxImagesCard from "./boxImagesCard/BoxImagesCard";
import Masonry from "react-masonry-css";
import styles from "./HelpingOthersBoxImages.module.css";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import ImagePopUp from "../../../components/imagePopUp/ImagePopUp";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";

const HelpingOthersBoxImages = () => {
  const [viewAll, setViewAll] = useState(false);
  const [imagePopUp, setImagePopUp] = useState(false);
  const [layoutId, setLayoutId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [posters, setPosters] = useState([]);

  useEffect(() => {
    return onSnapshot(
      query(
        collection(db, "helpingOthersBoxImage"),
        orderBy("timestamp", "desc")
      ),
      (snapshot) => {
        setPosters(snapshot.docs);
      }
    );
  }, []);

  const popup = (id, image) => {
    if (!imagePopUp) {
      setImagePopUp(true);
      setLayoutId(id);
      setImageUrl(image);
    } else {
      setLayoutId(null);
      setImagePopUp(false);
      setImageUrl(null);
    }
  };

  const breakpoints = {
    default: 4,
    1267: 3,
    950: 2,
    558: 1,
  };

  return (
    <div className="w-[95%] mx-auto mt-10">
      <AnimateSharedLayout>
        {!viewAll && (
          <div>
            <Masonry
              breakpointCols={breakpoints}
              className={styles.masnoryGrid}
              columnClassName={styles.masnoryGridColumn}
            >
              {posters.map((data, i) => {
                if (i > 7) return;
                return (
                  <div className="flex justify-center">
                    <BoxImagesCard
                      popup={popup}
                      key={i}
                      image={data.data().image}
                      id={data.id}
                    />
                  </div>
                );
              })}
            </Masonry>

            {posters.length > 8 && (
              <center
                onClick={() => setViewAll(true)}
                className={`cursor-pointer mt-5 ${styles.secondaryText}`}
              >
                VIEW MORE
              </center>
            )}
          </div>
        )}

        {viewAll && (
          <div>
            <Masonry
              breakpointCols={breakpoints}
              className={styles.masnoryGrid}
              columnClassName={styles.masnoryGridColumn}
            >
              {posters.map((data, i) => {
                return (
                  <div className="flex justify-center">
                    <BoxImagesCard
                      popup={popup}
                      key={i}
                      image={data.data().image}
                      id={data.id}
                    />
                  </div>
                );
              })}
            </Masonry>

            <center
              onClick={() => setViewAll(false)}
              className={`cursor-pointer mt-5 ${styles.secondaryText}`}
            >
              VIEW LESS
            </center>
          </div>
        )}

        {/* Modal */}
        <div>
          <AnimatePresence>
            {imagePopUp && (
              <ImagePopUp
                popup={popup}
                imageUrl={imageUrl}
                layoutId={layoutId}
              />
            )}
          </AnimatePresence>
        </div>
      </AnimateSharedLayout>
    </div>
  );
};

export default HelpingOthersBoxImages;
