import React from "react";
import UpcomingEvents from "../../homePage/upcomingEvents/UpcomingEvents";

const EventsCaurosel = () => {
  return (
    <div className="mt-10">
      <UpcomingEvents />
    </div>
  );
};

export default EventsCaurosel;
