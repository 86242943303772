import React from "react";
import styles from "./HypeDefination.module.css";

const HypeDefination = ({ letter, title, description }) => {
  return (
    <div className="px-5 lg:px-36 flex flex-col lg:flex-row lg:space-x-24 items-center">
      {/* Hype Letter */}
      <div>
        <h3
          className={`text-primary text-[8rem] lg:text-[10rem] ${styles.hypeFont}`}
        >
          {letter}
        </h3>
      </div>

      {/* Hype description */}
      <div className="text-center lg:text-start">
        {/* Title */}
        <div>
          <h5 className="text-primary text-[1.6rem] lg:text-[2rem]">{title}</h5>
        </div>

        {/* Description */}
        <div>
          <p className={`${styles.secondaryText}`}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default HypeDefination;
