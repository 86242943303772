import React, { useEffect } from "react";
import styles from "./Disclamer.module.css";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

const Disclamer = () => {
  const { ref, inView } = useInView();

  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7 },
      });

      descriptionAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7, delay: 0.3 },
      });
    }
  }, [inView, titleAnimation, descriptionAnimation]);

  return (
    <div ref={ref}>
      <motion.h2
        initial={{ opacity: 0, y: "100%" }}
        animate={titleAnimation}
        className="text-center text-primary text-[1.8rem] lg:text-[2.5rem]"
      >
        DISCLAIMER
      </motion.h2>

      <motion.p
        initial={{ opacity: 0, y: "100%" }}
        animate={descriptionAnimation}
        className={`text-center mt-2 ${styles.secondaryText}`}
      >
        We are a member club of the Florida Region of USA Volleyball. As a
        player you may stop any unwanted contact from a club representative by
        simply asking (either verbally or in writing) that all contact cease.
        Any player believing a club representative of any Florida Region
        volleyball club has been intimidating, harassing, or acted
        inappropriately in any manner of contact or recruiting should contact
        the Florida Region office at (352) 742-0080
      </motion.p>

      {/* Text */}
      <div className="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-16 mt-10">
        {/* Leo */}
        <div>
          <motion.p
            initial={{ opacity: 0, y: "100%" }}
            animate={descriptionAnimation}
            className={`text-center text-[1.2rem] mt-2 text-bolder ${styles.secondaryText}`}
          >
            Leo Grossman
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: "100%" }}
            animate={descriptionAnimation}
            className={`text-center mt-2 text-gray-500 ${styles.secondaryText}`}
          >
            Director
          </motion.p>
          <div className="flex justify-center flex-col md:flex-row">
            <motion.a
              initial={{ opacity: 0, y: "100%" }}
              href={`tel:+1 (813) 352-1742`}
              animate={descriptionAnimation}
              className={`text-center mt-2 ${styles.secondaryText}`}
            >
              +1 (813) 352-1742
            </motion.a>
          </div>
        </div>

        {/* Amanda */}
        {/* <div>
          <motion.p
            initial={{ opacity: 0, y: "100%" }}
            animate={descriptionAnimation}
            className={`text-center mt-2 text-[1.2rem] text-bolder ${styles.secondaryText}`}
          >
            Amanda Southard
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: "100%" }}
            animate={descriptionAnimation}
            className={`text-center mt-2 text-gray-500 ${styles.secondaryText}`}
          >
            Director
          </motion.p>{" "}
          <div className="flex justify-center">
            <motion.a
              initial={{ opacity: 0, y: "100%" }}
              href={`tel:+1 (813) 495-0530`}
              animate={descriptionAnimation}
              className={`text-center mt-2 ${styles.secondaryText}`}
            >
              +1 (813) 495-0530
            </motion.a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Disclamer;
