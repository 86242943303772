import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import Masonry from "react-masonry-css";
import styles from "./CoachesSection.module.css";
import TeamCard from "./teamCard/TeamCard";
import Fade from "react-reveal/Fade";
import TeamLoader from "../teamLoader/TeamLoader";

const CoachesSection = () => {
  const [coachesData, setCoachesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loaderArray = Array(3).fill(0);

  useEffect(() => {
    return onSnapshot(
      query(collection(db, "coaches"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setCoachesData(snapshot.docs);
        setIsLoading(false);
      }
    );
  }, [db]);

  const breakpoints = {
    default: 5,
    2066: 4,
    1607: 3,
    1194: 2,
    737: 1,
  };

  return (
    <div>
      <div>
        <h2 className="text-center text-primary text-[2rem] lg:text-[2.5rem]">
          Our Coaches
        </h2>
      </div>

      {/* Coaches list */}
      {isLoading ? (
        <div className="w-[95%] lg:w-[90%] mx-auto mt-10">
          <Masonry
            breakpointCols={breakpoints}
            className={styles.masnoryGrid}
            columnClassName={styles.masnoryGridColumn}
          >
            {loaderArray.map((data, i) => {
              return (
                <div className="w-full ml-1.5 flex justify-center" key={i}>
                  <Fade bottom>
                    <TeamLoader key={i} />
                  </Fade>
                </div>
              );
            })}
          </Masonry>
        </div>
      ) : (
        <div className="w-[95%] lg:w-[90%] mx-auto mt-10 flex justify-center">
          <div className="grid sm:grid-cols-2 sm:gap-x-3 xl:grid-cols-3 2xl:grid-cols-4 2xl:gap-x-8">
            {coachesData.map((coachData, i) => {
              return (
                <div className="w-full ml-1.5 flex justify-center" key={i}>
                  <Fade bottom>
                    <TeamCard
                      name={coachData.data().name}
                      image={coachData.data().image}
                      role={coachData.data().role}
                      description={coachData.data().description}
                      id={coachData.id}
                    />
                  </Fade>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachesSection;
