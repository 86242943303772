import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { db } from "../../../firebase";
import UpComingEventCard from "./upcomingEventCard/UpComingEventCard";
import styles from "./UpcomingEvents.module.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const UpcomingEvents = () => {
  const [eventData, setEventData] = useState([]);

  // fetch events data
  const getEventsData = async () => {
    return onSnapshot(
      query(collection(db, "events"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setEventData(snapshot.docs);
      }
    );
  };

  useEffect(() => {
    getEventsData();
  }, []);

  return (
    <div>
      <div
        className={`${styles.upcomingEventsContainer} py-10 ${
          eventData.length === 0 ? "h-[300px]" : "min-h-[520px]"
        }`}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-[#00000063]">
          <div className="w-[80%] mx-auto max-w-6xl py-10">
            {/* Heading */}
            <div>
              <h2
                className={`text-primary text-[2.5rem] text-center ${styles.header}`}
              >
                UPCOMING EVENTS
              </h2>
            </div>

            <div>
              {eventData.length === 0 ? (
                <div className="flex justify-center items-center h-[150px]">
                  <p className="text-white text-center text-[1.5rem]">
                    No Upcoming Events
                  </p>
                </div>
              ) : (
                <div>
                  {/* Caurosel */}
                  <div className="mt-10">
                    <Carousel
                      className={`flex ${
                        eventData.length < 2 && "md:justify-center"
                      } ${eventData.length < 4 && "lg:justify-center"}`}
                      responsive={responsive}
                      infinite={true}
                      autoPlay={false}
                    >
                      {eventData.map((events, i) => {
                        return (
                          <div className="flex justify-center">
                            <UpComingEventCard
                              key={i}
                              name={events.data().eventName}
                              image={events.data().image}
                              date={`${events.data().startDate} - ${
                                events.data().endDate
                              }`}
                              id={events.id}
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
