import React from "react";
import { Link } from "react-router-dom";
import styles from "./CoachCard.module.css";

const TeamCard = ({ image, name, role, description, id, type }) => {
  return (
    <Link to={type === "director" ? `/teams/director/${id}` : `/teams/${id}`}>
      <div className="h-[310px]  w-[350px] bg-white rounded-md mt-[120px] relative">
        {/* Image */}
        <div className="absolute top-0 left-0 w-full flex justify-center -mt-24 p-2">
          <img
            className="object-cover h-[250px] w-[250px] rounded-full"
            src={image}
            alt={name}
          />
        </div>

        {/* Name */}
        <div className="pt-[170px]">
          <h4 className="text-center text-primary">{name}</h4>
        </div>

        {/* Role */}
        <div>
          <p className={`text-center text-[0.8rem] ${styles.secondaryText}`}>
            {role}
          </p>
        </div>

        {/* Description */}
        <div className="px-3 mt-2 text-ellipsis overflow-hidden h-[70px] w-[350px]">
          <p className={`text-center font-bold   ${styles.secondaryText}`}>
            {description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default TeamCard;
