import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import styles from "./EmailSentMessage.module.css";

const EmailSentMessage = ({ state, closeEmailSentMessageModal }) => {
  return (
    <Transition appear show={state} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={closeEmailSentMessageModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className={`text-[1.8rem] font-large text-center leading-6 text-primary`}
                >
                  Email Sent
                </Dialog.Title>

                <p className={`text-center mt-5 ${styles.secondaryText}`}>
                  Thank you for contacting the Florida HYPE volleyball. One of
                  our directors will be in touch with you soon.
                </p>

                <div className="flex justify-center mt-3">
                  <button
                    onClick={() => closeEmailSentMessageModal()}
                    className="bg-primary px-9 py-2 mt-5 text-white rounded-full"
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmailSentMessage;
