import React from "react";

const PaymentOptionCard = ({ image, price, name, paymentLink }) => {
  // Function to handle pay click
  const handlePayClick = () => {
    window.open(paymentLink);
  };
  return (
    <div className="w-[300px]">
      {/* Image */}
      <div className="w-[300px] h-[200px]">
        <img
          className="h-[200px] w-[300px] object-cover rounded-md"
          src={image}
          alt={name}
        />
      </div>

      {/* Package Name */}
      <div>
        <h4 className="text-primary text-[1.7rem] text-center">{name}</h4>
      </div>

      {/* Price */}
      <div className="mt-3">
        <p className="text-center text-[1.2rem]">${price}</p>
      </div>

      {/* Payment Button */}
      <div className="flex justify-center mt-2">
        <button
          onClick={() => handlePayClick()}
          className="px-8 py-2 bg-primary rounded-full text-white"
        >
          Pay
        </button>
      </div>
    </div>
  );
};

export default PaymentOptionCard;
