import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import styles from "./SingleCoachPage.module.css";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const SingleCoachPage = () => {
  const topRef = useRef();
  const { id } = useParams();
  const [coachData, setCoachData] = useState({});

  const getData = async () => {
    const docRef = doc(db, "coaches", id);
    const data = await getDoc(docRef);

    setCoachData(data.data());
  };

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    getData();
  }, []);

  const handleBackClick = () => {
    window.history.go(-1);
  };

  return (
    <div ref={topRef} className="min-h-[50vh]">
      <div className="flex flex-col lg:flex-row px-10 lg:px-0 justify-center mt-7 lg:mt-16 lg:space-x-10 ">
        {/* Back Button Section */}
        <div className="lg:hidden">
          {coachData.description && (
            <div
              onClick={() => handleBackClick()}
              className="flex items-center space-x-2 text-gray-700 mb-5"
            >
              <ArrowLeftIcon className="h-7" /> <p>Back</p>
            </div>
          )}
        </div>
        {/* Image */}
        <div>
          <img
            className="w-[90vw] lg:w-auto  md:h-[550px] object-contain"
            src={coachData.image}
            alt={coachData.name}
          />
        </div>

        {/* Name and Description */}
        <div className="mt-5 md:mt-0 text-center md:text-start">
          {/* Name */}
          <div>
            <h4 className="text-[3rem] text-primary ">{coachData.name}</h4>
          </div>

          {/* Descriptoin */}
          <div className="lg:max-w-[50vw] mt-5 ">
            <p className={styles.secondaryText}>{coachData.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCoachPage;
