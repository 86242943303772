import React, { useEffect } from "react";
import styles from "./ContactHeader.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ContactHeader = () => {
  const { ref, inView } = useInView();

  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        opacity: 1,
        transition: { duration: 1 },
      });

      descriptionAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 0.5 },
      });
    }
  }, [inView, titleAnimation, descriptionAnimation]);
  return (
    <div>
      <div
        className={`w-[100vw] h-[50vh] lg:h-[70vh] relative ${styles.headerImageContainer}`}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-[#000000ae] px-20">
          <div className="flex items-center lg:items-end flex-col justify-center h-full w-[100%] ml-auto">
            {/* Heading  */}
            <div className="w-full text-center">
              <motion.h2
                initial={{ opacity: 0 }}
                animate={titleAnimation}
                className="text-[2rem] text-center lg:text-[3rem] text-white"
              >
                CONTACT US
              </motion.h2>
            </div>

            {/* Description */}
            <div className="w-full" ref={ref}>
              <motion.p
                initial={{ opacity: 0 }}
                animate={descriptionAnimation}
                className={`text-white text-center mt-5  ${styles.description}`}
              >
                Founded in 2022, Florida HYPE Volleyball is breathing new life
                into local youth club volleyball.
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHeader;
