import React, { useEffect } from "react";
import ContactForm from "./contactForm/ContactForm";
import ContactHeader from "./contactHeader/ContactHeader";

const CantactPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      {/* Header */}
      <ContactHeader />

      {/* Contact Form */}
      <div className="w-[80%] mt-20 mx-auto max-w-6xl">
        <ContactForm />
      </div>
    </div>
  );
};

export default CantactPage;
