import React from "react";
import styles from "./UsavSection.module.css";

const UsavSection = () => {
  // Function to handle visit click
  const handleVisitClick = () => {
    window.open("https://usavolleyball.org/about/");
  };

  return (
    <div className={styles.usavContainer}>
      <div className="w-[80%] mx-auto max-w-6xl flex flex-col items-center justify-center py-12">
        {/* Logo */}
        <div className="h-[150px]">
          <img
            className="h-[50px] md:h-[80px]"
            src="/logo/usavLogo.svg"
            alt="AAU Logo"
          />
        </div>

        {/* Description */}
        <div className="-mt-12 md:-mt-10 lg:-mt-8">
          <p className={`text-center ${styles.descriptionText}`}>
            USA Volleyball is the National Governing Body (NGB) for the sport of
            volleyball in the United States and is recognized as such by the
            International Volleyball Federation (FIVB) and the United States
            Olympic and Paralympic Committee (USOPC).
          </p>
        </div>

        {/* Visit */}
        <div className="mt-10">
          <button
            onClick={() => handleVisitClick()}
            className="text-white bg-primary px-10 py-3 rounded-full"
          >
            Visit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsavSection;
