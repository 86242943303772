import React, { useEffect } from "react";
import CoachesSection from "./coachesSection/CoachesSection";
import DirectorsSection from "./directorsSection/DirectorsSection";

const Teamspage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="mt-10">
        <DirectorsSection />
      </div>
      <div className="mt-20">
        <CoachesSection />
      </div>
    </div>
  );
};

export default Teamspage;
