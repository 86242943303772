import React from "react";
import { motion } from "framer-motion";

const BoxImagesCard = ({ image, popup, id }) => {
  return (
    <div className="mt-5">
      <motion.img
        onClick={() => popup(id, image)}
        className="h-[250px] w-[250px] rounded-md object-cover cursor-pointer"
        src={image}
        loading="lazy"
      />
    </div>
  );
};

export default BoxImagesCard;
