import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import styles from "./JoinHypeModal.module.css";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import BeatLoader from "react-spinners/BeatLoader";
import { db } from "../../firebase";

const JoinHypeModal = ({ modalState, closeJoinModal, from }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumer] = useState("");
  const [email, setEmail] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  // reset show message
  useEffect(() => {
    if (modalState) {
      setShowMessage(false);
    }
  }, [modalState]);

  // Function to clear all State
  const clearStateAndCloseModal = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumer("");
    setEmail("");
    closeJoinModal();
  };

  // Function to handle form submit
  const handleFormSubmit = async () => {
    if (isFormSubmitted) return;
    setIsFormSubmitted(true);

    const addResponse = await addDoc(collection(db, from), {
      firstName,
      lastName,
      phoneNumber,
      email,
      timestamp: serverTimestamp(),
    });

    console.log(addResponse);

    if (addResponse.id) {
      setIsFormSubmitted(false);
      setShowMessage(true);
    }
  };
  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={clearStateAndCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {showMessage ? (
                  <div className="py-3">
                    <h2 className="text-center text-[1.5rem] text-primary">
                      Thank you for believing the Hype
                    </h2>

                    {/* Button */}
                    <div className="flex justify-center mt-10">
                      <button
                        onClick={() => clearStateAndCloseModal()}
                        className="px-8 py-2 bg-primary text-white rounded-full"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* Name */}
                    <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:items-center justify-between">
                      {/* First name */}
                      <div>
                        <h6>First Name</h6>
                        <input
                          className={`formInput ${styles.formImput}`}
                          placeholder="First Name"
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>

                      {/* Last Name */}
                      <div>
                        <h6>Last Name</h6>
                        <input
                          className={`formInput ${styles.formImput}`}
                          placeholder="Last Name"
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Phone Number */}
                    <div className="mt-3">
                      <h6>Phone No.</h6>
                      <input
                        className={`formInput ${styles.formImput}`}
                        placeholder="Phone Number"
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumer(e.target.value)}
                      />
                    </div>

                    {/* Email */}
                    <div className="mt-3">
                      <h6>Email</h6>
                      <input
                        className={`formInput ${styles.formImput}`}
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    {/* Button */}
                    <div className="flex justify-center mt-10">
                      {isFormSubmitted ? (
                        <button className="px-8 py-2 flex items-center bg-primary text-white rounded-full">
                          Saving
                          <div className="-mb-2">
                            <BeatLoader color="white" size={7} />
                          </div>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleFormSubmit()}
                          className="px-8 py-2 bg-primary text-white rounded-full"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default JoinHypeModal;
