import React from "react";
import ClubDetails from "./clubDetails/ClubDetails";
import Form from "./form/Form";

const ContactForm = () => {
  return (
    <div className="flex justify-center flex-col lg:flex-row lg:space-x-20">
      {/* Form */}
      <div>
        <Form />
      </div>

      {/* Details */}
      <div className="mt-10 lg:mt-0">
        <ClubDetails />
      </div>
    </div>
  );
};

export default ContactForm;
