import HomeHeader from "./homeHeader/HomeHeader";
import Partners from "./partners/Partners";
import UpcomingEvents from "./upcomingEvents/UpcomingEvents";
import styles from "./HomePage.module.css";
import { useEffect, useState } from "react";
import AauSection from "./aauSection/AauSection";
import OurMissionSection from "./ourMissionSection/OurMissionSection";
import DonationSection from "./donationSection/DonationSection";
import InstagramSection from "./instagramSection/InstagramSection";
import JoinHypeModal from "../../components/joinHypeModal/JoinHypeModal";
import JoinHypeSection from "./joinHypeSection/JoinHypeSection";
import Disclamer from "./disclamer/Disclamer";
import UsavSection from "./usavSection/UsavSection";

const HomePage = () => {
  const [formFrom, setFormFrom] = useState("");
  const [openJoinModal, setOpenJoinModal] = useState(false);

  // Function to close joni modal
  const closeJoinModal = () => setOpenJoinModal(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      {/* Header */}
      <HomeHeader />

      {/* Registration */}
      <div className="w-[80%] mx-auto max-w-6xl space-y-20">
        <OurMissionSection
          setFormFrom={setFormFrom}
          setOpenJoinModal={setOpenJoinModal}
        />
      </div>

      {/* Upcoming Events */}
      <div className={`mt-20 md:py-10 relative`}>
        <div className="mt-16 md:mt-0">
          <UpcomingEvents />
        </div>
      </div>

      <div className="w-[80%] mx-auto max-w-6xl space-y-20 mt-20">
        {/* Partners */}
        <Partners />
      </div>

      <div
        className={`mt-20 py-16 lg:py-10 h-[700px] md:h-[600px] lg:h-[500px] flex items-center justify-center text-center relative  ${styles.thakyouMessageContainer}`}
      >
        <div className="w-[80%] mx-auto max-w-6xl">
          {/* Hype Anouncements */}
          <DonationSection />
        </div>
      </div>

      <div className="w-[80%] mx-auto max-w-6xl mt-10 space-y-20">
        {/* DISCLAMER */}
        <Disclamer />
      </div>

      {/* AAU Section */}
      <div className="mt-20">
        <AauSection />
      </div>

      {/* USAV Section */}
      <div className="mt-20">
        <UsavSection />
      </div>

      {/* WHy Hype */}
      <div className="mt-20">
        <InstagramSection />
      </div>

      <div className="w-[80%] mx-auto max-w-6xl mt-10 space-y-20">
        {/* Join The Hype */}
        <JoinHypeSection
          setFormFrom={setFormFrom}
          setOpenJoinModal={setOpenJoinModal}
        />
      </div>

      {/* Join Form Modal */}
      <JoinHypeModal
        modalState={openJoinModal}
        closeJoinModal={closeJoinModal}
        from={formFrom}
      />
    </div>
  );
};

export default HomePage;
