import React, { useEffect, useState } from "react";
import styles from "./WhyHypeHeader.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";
import JoinHypeModal from "../../../components/joinHypeModal/JoinHypeModal";

const WhyHypeHeader = () => {
  const { ref, inView } = useInView();
  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const buttonAnimation = useAnimation();
  const [headerData, setHeaderData] = useState({});
  const [openJoinModal, setOpenJoinModal] = useState(false);

  // Function to close joni modal
  const closeJoinModal = () => setOpenJoinModal(false);

  // Function to get heading data
  useEffect(() => {
    return onSnapshot(query(collection(db, "whyHypeHeading")), (snapshot) => {
      setHeaderData(snapshot.docs[0]);
    });
  }, []);

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        opacity: 1,
        transition: { duration: 1 },
      });

      descriptionAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 0.5 },
      });

      buttonAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 1 },
      });
    }
  }, [inView, titleAnimation, descriptionAnimation, buttonAnimation]);
  return (
    <div>
      <div
        className={`w-[100vw] h-[70vh] lg:h-[80vh] relative ${styles.headerImageContainer}`}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-[#000000ae] px-20">
          <div className="flex items-center flex-col justify-center h-full w-[100%] ml-auto">
            {/* Heading  */}
            <div className="w-full">
              <motion.h2
                initial={{ opacity: 0 }}
                animate={titleAnimation}
                className="text-[2rem] text-center  lg:text-[3rem] text-white"
              >
                {headerData.id && headerData?.data().title}
              </motion.h2>
            </div>

            {/* Description */}
            <div className="w-full" ref={ref}>
              <motion.p
                initial={{ opacity: 0 }}
                animate={descriptionAnimation}
                className={`text-white text-center mt-5 w-full  ${styles.description}`}
              >
                {headerData.id && headerData?.data().subtitle}
              </motion.p>
            </div>

            {/* Contact us */}
            <div className="mt-10 flex justify-center items-start w-[40vw]">
              <Link to="/contact-us">
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={buttonAnimation}
                  className="bg-primary px-9 py-4 rounded-full text-white whitespace-nowrap"
                >
                  Contact us
                </motion.button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Join Form Modal */}
      <JoinHypeModal
        modalState={openJoinModal}
        closeJoinModal={closeJoinModal}
      />
    </div>
  );
};

export default WhyHypeHeader;
