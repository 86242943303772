import React from "react";
import { Routes, Route } from "react-router-dom";
import TwentyTwoTwentyThree from "../app/2022-2023/2022-2023";
import CantactPage from "../app/contactUsPage/CantactPage";
import DocumentsPage from "../app/documentsPage/DocumentsPage";
import Footer from "../app/footer/Footer";
import Header from "../app/header/Header";
import HeaderBurgerMenu from "../app/header/headerBurgerMenu/HeaderBurgerMenu";
import HelpingOthersPage from "../app/helpingOthersPage/HelpingOthersPage";
import HomePage from "../app/homePage/HomePage";
import PaymentPage from "../app/paymentPage/PaymentPage";
import SingleCoachPage from "../app/singleCoachPage/SingleCoachPage";
import SingleDirectorPage from "../app/singleDirectorPage/SingleDirectorPage";
import Teamspage from "../app/teamsPage/TeamsPage";
import WhyHypePage from "../app/whyHypePage/WhyHypePage";

const Router = () => {
  return (
    <div>
      {/* Header */}
      <div className="sticky top-0 z-20">
        <div className="hidden lg:flex">
          <Header />
        </div>

        <div className="lg:hidden">
          <HeaderBurgerMenu />
        </div>
      </div>

      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/why-HYPE" element={<WhyHypePage />} />
          <Route path="/hype-team" element={<Teamspage />} />
          <Route path="/teams/director/:id" element={<SingleDirectorPage />} />
          <Route path="/teams/:id" element={<SingleCoachPage />} />
          <Route path="/contact-us" element={<CantactPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/2023-2024" element={<TwentyTwoTwentyThree />} />
          <Route path="/helping-others" element={<HelpingOthersPage />} />
          <Route path="/documents" element={<DocumentsPage />} />
        </Routes>
      </div>

      {/* Footer */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Router;
