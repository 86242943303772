import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Carousel from "react-multi-carousel";
import { motion, useAnimation } from "framer-motion";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";

const Partners = () => {
  const { ref, inView } = useInView();
  const [partnersData, setPartnersData] = useState([]);

  useEffect(() => {
    return onSnapshot(
      query(collection(db, "partners"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setPartnersData(snapshot.docs);
      }
    );
  }, [db]);

  const handleLinkVisit = (link) => {
    console.log("working");
    window.open(link);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const titleAnimation = useAnimation();
  const partnersAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        opacity: 1,
        y: "0%",
        transition: {
          duration: 0.7,
        },
      });

      partnersAnimation.start({
        opacity: 1,
        y: "0%",
        transition: {
          duration: 0.7,
          delay: 0.3,
        },
      });
    }
  }, [inView]);

  return (
    <div className="lg:space-x-9  mx-auto">
      <div className="flex items-center justify-center">
        <motion.p
          initial={{ opacity: 0, y: "100%" }}
          animate={titleAnimation}
          className="text-[2.5rem] text-primary"
        >
          OUR PARTNERS
        </motion.p>
      </div>

      <div ref={ref}>
        <motion.div
          initial={{ opacity: 0, y: "100%" }}
          animate={partnersAnimation}
          className=""
        >
          <Carousel
            className={`flex ${
              partnersData.length < 2 && "md:justify-center"
            } ${partnersData.length < 4 && "lg:justify-center"}`}
            responsive={responsive}
            infinite={true}
            autoPlay={false}
          >
            {partnersData.map((partner, i) => {
              return (
                <div className="flex items-center justify-center" key={i}>
                  <img
                    onClick={() => handleLinkVisit(partner.data().link)}
                    className="h-[200px] w-[200px] object-contain grayscale hover:grayscale-0 cursor-pointer"
                    src={partner.data().image}
                    alt="Partner logo"
                  />
                </div>
              );
            })}
          </Carousel>
        </motion.div>
      </div>
    </div>
  );
};

export default Partners;
