import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import React, { useEffect, useState } from "react";
import ImagePopUp from "../../../components/imagePopUp/ImagePopUp";
import { db } from "../../../firebase";
import TwentyThreePosterCard from "./twentyThreePosterCard/TwentyThreePosterCard";
import Fade from "react-reveal/Fade";

const TwentyThreePosters = () => {
  const [imagePopUp, setImagePopUp] = useState(false);
  const [layoutId, setLayoutId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [posters, setPosters] = useState([]);

  // FUnction to get posters data
  const getPosters = () => {
    onSnapshot(
      query(collection(db, "2020-2023Posters"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setPosters(snapshot.docs);
      }
    );
  };

  useEffect(() => {
    getPosters();
  }, []);

  const popup = (id, image) => {
    if (!imagePopUp) {
      setImagePopUp(true);
      setLayoutId(id);
      setImageUrl(image);
    } else {
      setLayoutId(null);
      setImagePopUp(false);
      setImageUrl(null);
    }
  };

  return (
    <div>
      <AnimateSharedLayout>
        {/* Posters */}
        <div className="mt-10">
          {posters.map((image, i) => {
            return (
              <Fade bottom>
                <TwentyThreePosterCard
                  key={i}
                  popup={popup}
                  image={image.data().poster}
                  id={image.id}
                  title={image.data().title}
                  subtitle={image.data().subtitle}
                />
              </Fade>
            );
          })}
        </div>

        {/* Modal */}
        <div>
          <AnimatePresence>
            {imagePopUp && (
              <ImagePopUp
                popup={popup}
                imageUrl={imageUrl}
                layoutId={layoutId}
              />
            )}
          </AnimatePresence>
        </div>
      </AnimateSharedLayout>
    </div>
  );
};

export default TwentyThreePosters;
