import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <div className="w-full h-[95px] bg-white shadow-md">
      <div className="w-[80%] mx-auto flex items-center justify-center space-x-10">
        {/* Home */}
        <div>
          <Link className={`${pathname === "/" && "text-primary"}`} to="/">
            Home
          </Link>
        </div>

        {/* About */}
        <div>
          <Link
            className={`${pathname === "/why-HYPE" && "text-primary"}`}
            to="/why-HYPE"
          >
            Why HYPE
          </Link>
        </div>

        {/* Hype Team */}
        <Link
          className={`${pathname === "/hype-team" && "text-primary"}`}
          to="/hype-team"
        >
          The HYPE Team
        </Link>

        {/* Logo */}
        <Link to="/">
          <div className="-mt-5">
            <img
              className="h-32 hover:scale-110 transition transform ease-in-out"
              src="/logo/logoGray.png"
              alt="logo"
            />
          </div>
        </Link>

        {/* 2022 - 2023 */}
        <Link
          className={`${pathname === "/2023-2024" && "text-primary"}`}
          to="/2023-2024"
        >
          2023/2024
        </Link>

        {/* Helping Others */}
        <Link
          className={`${pathname === "/helping-others" && "text-primary"}`}
          to="/helping-others"
        >
          Helping Others
        </Link>

        {/* Contact us */}
        <Link
          className={`${pathname === "/contact-us" && "text-primary"}`}
          to="/contact-us"
        >
          Contact us
        </Link>
      </div>
    </div>
  );
};

export default Header;
