import React, { useRef, useState } from "react";
import styles from "./Form.module.css";
import emailjs from "@emailjs/browser";
import ButtonLoader from "../../../../components/buttonLoader/ButtonLoader";
import EmailSentMessage from "./emailSentMessage/EmailSentMessage";

const Form = () => {
  const formRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isFormSubmitted, setIsformSubmitted] = useState(false);
  const [openEmailSentModal, setOpenEmailSentModal] = useState(false);

  // Function to close email sent modal
  const closeEmailSentMessageModal = () => setOpenEmailSentModal(false);

  // Function to clear all states
  const clearAllStates = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
  };

  // Function to handle send message
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (isFormSubmitted) return;
    setIsformSubmitted(true);

    emailjs
      .sendForm(
        "service_wkqzmon",
        "template_j45g4sc",
        formRef.current,
        "_kepsqpyC8N59dco_"
      )
      .then(
        (result) => {
          if (result.status === 200) {
            console.log("Email Sent");
            setIsformSubmitted(false);
            setOpenEmailSentModal(true);
            clearAllStates();
          }
        },
        (error) => {
          console.log(error.text);
          setIsformSubmitted(false);
        }
      );
  };

  return (
    <div>
      {/* title */}
      <div>
        <h2 className="text-primary text-[2.5rem]">HAVE QUESTIONS?</h2>
      </div>

      {/* Details */}
      <p className={styles.secondaryText}>
        Fill out the form below in order to get in touch with our
        representatives.
      </p>

      {/* Form */}
      <form ref={formRef} className="space-y-5 flex flex-col mt-5">
        {/* Name */}
        <input
          type="text"
          className={`contactInput ${styles.secondaryText}`}
          placeholder="Your Name*"
          value={name}
          name="name"
          onChange={(e) => setName(e.target.value)}
        />

        {/* Email */}
        <input
          type="email"
          name="email"
          className={`contactInput ${styles.secondaryText}`}
          placeholder="Your Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Phone */}
        <input
          type="number"
          className={`contactInput ${styles.secondaryText}`}
          placeholder="Phone"
          name="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        {/* Subject */}
        <input
          type="text"
          className={`contactInput ${styles.secondaryText}`}
          placeholder="Subject"
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        {/* Message */}
        <textarea
          type="text"
          className={`contactInput ${styles.secondaryText}`}
          placeholder="Message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </form>
      {/* Button */}

      {isFormSubmitted ? (
        <button
          type="submit"
          className="bg-primary px-9 py-4 mt-5 text-white rounded-full flex"
        >
          Sending...
          <ButtonLoader />
        </button>
      ) : (
        <button
          type="submit"
          onClick={(e) => handleSendMessage(e)}
          className="bg-primary px-9 py-4 mt-5 text-white rounded-full"
        >
          Send Message
        </button>
      )}

      <EmailSentMessage
        state={openEmailSentModal}
        closeEmailSentMessageModal={closeEmailSentMessageModal}
      />
    </div>
  );
};

export default Form;
