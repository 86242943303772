import React from "react";
import styles from "./AauSection.module.css";

const AauSection = () => {
  // Function to handle visit click
  const handleVisitClick = () => {
    window.open("https://aausports.org/index.php");
  };

  return (
    <div className={styles.aauContainer}>
      <div className="w-[80%] mx-auto max-w-6xl flex flex-col items-center justify-center py-12">
        {/* Logo */}
        <div>
          <img className="h-[150px]" src="/logo/aaulogo.png" alt="AAU Logo" />
        </div>

        {/* Description */}
        <div className="mt-5">
          <p className={`text-white text-center ${styles.descriptionText}`}>
            The Amateur Athletic Union (AAU) has been raising champions for more
            than a century. Since its inception in 1888, the AAU has set the
            standard for amateur sports in the United States with one goal in
            mind: “Sports For All, Forever.” It is more than a motto – it has
            been the focus and drive of AAU for 130 years and counting for
            sports in the United States and throughout the world.
          </p>
        </div>

        {/* Visit */}
        <div className="mt-10">
          <button
            onClick={() => handleVisitClick()}
            className="text-white bg-primary px-10 py-3 rounded-full"
          >
            Visit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AauSection;
