import { useAnimation, motion } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./OurMissionSection.module.css";

const OurMissionSection = ({ setOpenJoinModal, setFormFrom }) => {
  const { ref, inView } = useInView();

  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const buttonAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7 },
      });

      descriptionAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7, delay: 0.3 },
      });

      buttonAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7, delay: 0.6 },
      });
    }
  }, [inView, titleAnimation, descriptionAnimation, buttonAnimation]);

  // Function to handle Button Click
  const handleButtonClick = () => {
    setFormFrom("homeGetInTouch");
    setOpenJoinModal(true);
  };
  return (
    <div className="mt-16">
      {/* Description and Title */}
      <div className="">
        {/* Title */}
        <div>
          <motion.h2
            initial={{ opacity: 0, y: "100%" }}
            animate={titleAnimation}
            className={`text-primary text-center text-[2.5rem] ${styles.title}`}
          >
            OUR MISSION
          </motion.h2>
        </div>
        {/* Dscription */}
        <div className="mt-3">
          <motion.p
            initial={{ opacity: 0, y: "100%" }}
            animate={descriptionAnimation}
            ref={ref}
            className={`text-black text-center ${styles.description}`}
          >
            HYPE Volleyball is dedicated to
            <span
              className={`text-primary text-[1.4rem] ml-2 ${styles.hilightedText}`}
            >
              H
            </span>
            elping
            <span
              className={`text-primary text-[1.4rem] ml-2 ${styles.hilightedText}`}
            >
              Y
            </span>
            outh
            <span
              className={`text-primary text-[1.4rem] ml-2 ${styles.hilightedText}`}
            >
              P
            </span>
            erform with
            <span
              className={`text-primary text-[1.4rem] ml-2 ${styles.hilightedText}`}
            >
              E
            </span>
            xcellence in individual endeavors and sports. We pride ourselves on
            offering high-quality coaching that develops strong athletes within
            a unified club culture. Our core values focus on character,
            leadership, and philanthropic involvement within the community. It
            is more than a sport. It is Family.
          </motion.p>
        </div>

        {/* Button */}
        <div className="mt-10 flex justify-center">
          <motion.button
            onClick={() => handleButtonClick()}
            initial={{ opacity: 0, y: "100%" }}
            animate={buttonAnimation}
            className="bg-primary text-white px-9 py-3 tracking-wide font-bold rounded-full"
          >
            GET IN TOUCH
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default OurMissionSection;
