import React, { useEffect, useState } from "react";
import PaymentOptionCard from "./paymentOptionCard/PaymentOptionCard";
import Masonry from "react-masonry-css";
import styles from "./PaymentOptions.module.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";

const PaymentOptions = () => {
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    return onSnapshot(
      query(collection(db, "payment"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setPaymentData(snapshot.docs);
      }
    );
  }, [db]);

  const breakpoints = {
    default: 3,
    1182: 2,
    766: 1,
  };
  return (
    <div className="w-[80%] mx-auto max-w-6xl">
      {/* Header */}
      <div className="mt-20">
        <center className="text-[3rem] text-primary">Make a Payment</center>
      </div>

      {/* Payment Options */}
      <div className="flex mt-10 justify-center">
        <div className="grid sm:grid-cols-2 sm:gap-x-4 xl:grid-cols-3">
          {paymentData.map((data, i) => {
            return (
              <div className="mt-10" key={i}>
                <PaymentOptionCard
                  name={data.data().packageName}
                  price={data.data().price}
                  image={data.data().image}
                  paymentLink={data.data().paymentLink}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
