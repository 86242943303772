import { useAnimation, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styles from "./HelpingOthersHeroImage.module.css";
import { useInView } from "react-intersection-observer";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";

const HelpingOthersHeroImage = () => {
  const { ref, inView } = useInView();
  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const buttonAnimation = useAnimation();
  const [headerData, setHeaderData] = useState({});

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        opacity: 1,
        transition: { duration: 1 },
      });

      descriptionAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 0.5 },
      });

      buttonAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 1 },
      });
    }
  }, [inView, titleAnimation, descriptionAnimation, buttonAnimation]);

  const getHeadings = () => {
    onSnapshot(query(collection(db, "helpingOthersHeroTitle")), (snapshot) => {
      setHeaderData(snapshot.docs[0]);
    });
  };

  useEffect(() => {
    getHeadings();
  }, []);

  return (
    <div
      className={`w-full h-[65vh] lg:h-[80vh] relative ${styles.heroImageContainer}`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-[#000000ae]">
        <div className="flex items-center flex-col justify-center h-full w-[100%] ml-auto">
          {/* Heading  */}
          <div className="">
            <motion.h2
              initial={{ opacity: 0 }}
              animate={titleAnimation}
              className="text-[2rem] text-center  lg:text-[3rem] text-white"
            >
              {headerData.id && headerData?.data().title}
            </motion.h2>
          </div>

          {/* Description */}
          <div className="w-full" ref={ref}>
            <motion.p
              initial={{ opacity: 0 }}
              animate={descriptionAnimation}
              className={`text-white text-center mt-5 w-[90%] mx-auto  ${styles.description}`}
            >
              {headerData.id && headerData?.data().subtitle}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpingOthersHeroImage;
