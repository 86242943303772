import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import JoinHypeModal from "../../../components/joinHypeModal/JoinHypeModal";

const ActionButtons = () => {
  const navigate = useNavigate();
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  //   Function to close register modal
  const closeJoinModal = () => setOpenRegisterModal(false);
  return (
    <div className="flex flex-col md:flex-row space-y-7 md:space-y-0 justify-around items-center">
      {/* Register Button */}
      <div>
        <button
          onClick={() => setOpenRegisterModal(true)}
          className="largeActionButton"
        >
          REGISTER
        </button>
      </div>

      {/* PAYMENT BUTTON */}
      <div>
        <Link to="/payment">
          <button className="largeActionButton">MAKE A PAYMENT</button>
        </Link>
      </div>

      {/* Contact button */}
      <div>
        <button
          onClick={() => navigate("/contact-us")}
          className="largeActionButton"
        >
          CONTACT US
        </button>
      </div>

      <JoinHypeModal
        modalState={openRegisterModal}
        closeJoinModal={closeJoinModal}
      />
    </div>
  );
};

export default ActionButtons;
