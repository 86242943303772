import React, { useEffect, useState } from "react";
import PartnerDescription from "./partnerDescription/PartnerDescription";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";

const OurPartners = () => {
  const { ref, inView } = useInView();
  const [partnersData, setPartnersData] = useState([]);

  useEffect(() => {
    return onSnapshot(
      query(collection(db, "partners"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setPartnersData(snapshot.docs);
      }
    );
  }, [db]);

  const partnerAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      partnerAnimation.start({
        opacity: 1,
        transition: {
          duration: 0.7,
        },
      });
    }
  }, [inView, partnerAnimation]);

  return (
    <div>
      {/* title */}
      <div>
        <h2 className="text-center text-primary text-[2rem] lg:text-[2.5rem]">
          OUR PARTNERS
        </h2>
      </div>

      {/* Partners */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={partnerAnimation}
        ref={ref}
        className="flex items-center justify-around mt-8 flex-wrap"
      >
        {partnersData.map((partner, i) => {
          return (
            <div className="mt-3" key={i}>
              <PartnerDescription
                name={partner.data().name}
                image={partner.data().image}
                link={partner.data().link}
                description={partner.data().description}
              />
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default OurPartners;
