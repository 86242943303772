import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  const links = [
    {
      name: "Facebook",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#4b9cd3"
          stroke="#4b9cd3"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-facebook"
        >
          <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
        </svg>
      ),
      link: "https://www.facebook.com/profile.php?id=100071961623626",
    },
    {
      name: "Instagram",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4b9cd3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-instagram"
        >
          <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
          <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
        </svg>
      ),
      link: "https://www.instagram.com/florida_hype_volleyball/",
    },
  ];

  const moreHype = [
    {
      name: "Documents",
      link: "/documents",
    },
    {
      name: "Payment",
      link: "/payment",
    },
  ];

  const aboutHype = [
    {
      name: "Home",
      link: "/",
    },
    { name: "Why HYPE", link: "/why-HYPE" },
    { name: "HYPE Team", link: "/hype-team" },
    { name: "2023/2024", link: "/2023-2024" },
    { name: "Helping Others", link: "helping-others" },
    { name: "Contact us", link: "contact-us" },
  ];

  return (
    <div
      className={`bg-secondary text-white pt-16 pb-8 mt-20 ${styles.footerContainer}`}
    >
      {/* New */}
      <div className="w-[80%] mx-auto flex flex-col lg:flex-row justify-between">
        {/* Logo */}
        <div className="">
          <div>
            <img className="h-[200px]" alt="logo" src="/logo/logo.png" />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between w-[100%] lg:w-[50%]">
          {/* More Hype */}
          <div className="mt-5 lg:mt-0">
            <div>
              <h3 className="text-primary text-[1.5rem]">More HYPE</h3>
            </div>

            <div>
              <a
                href={require("../../clubHandBook/ClubHandBook.pdf")}
                download="FL HYPE Handbook"
                className="text-[1rem] cursor-pointer"
              >
                Club Handbook
              </a>
              {moreHype.map((data, i) => {
                return (
                  <div key={i}>
                    <Link to={data.link}>
                      <p className="text-[1rem]">{data.name}</p>
                    </Link>
                  </div>
                );
              })}
            </div>

            {/* Fl Hype */}
            <div className="mt-10">
              <h4 className="text-primary text-[1.4rem]">FL HYPE Volleyball</h4>
              <div className="flex space-x-5 mt-2">
                {links.map((data, i) => {
                  return (
                    <div
                      onClick={() => {
                        window.open(data.link);
                      }}
                      key={i}
                      className="cursor-pointer"
                    >
                      <div className="h-[50px] w-[50px] rounded-full bg-white flex items-center justify-center">
                        {data.icon}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* About Hype */}
          <div className="mt-10 lg:mt-0">
            <div>
              <h3 className="text-primary text-[1.5rem]">About HYPE</h3>
            </div>

            <div>
              {aboutHype.map((data, i) => {
                return (
                  <div key={i}>
                    <Link to={data.link}>
                      <p className="text-[1rem]">{data.name}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Copytight */}
      <div>
        <p
          className={`text-center mt-12 lg:mt-20 text-[0.9rem] ${styles.secondaryText}`}
        >
          Copyright ©️ 2022 FL HYPE Volleyball
        </p>
      </div>
    </div>
  );
};

export default Footer;
