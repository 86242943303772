import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const JoinHypeSection = ({ setOpenJoinModal, setFormFrom }) => {
  const { ref, inView } = useInView();
  const titleAnimtaion = useAnimation();
  const buttonAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      titleAnimtaion.start({
        opacity: 1,
        transition: {
          duration: 0.7,
        },
      });

      buttonAnimation.start({
        opacity: 1,
        transition: {
          duration: 0.7,
          delay: 0.3,
        },
      });
    }
  }, [inView, titleAnimtaion, buttonAnimation]);

  // Function to handle button click
  const handleButtonClick = () => {
    setFormFrom("homeSignUpNow");
    setOpenJoinModal(true);
  };

  return (
    <div>
      {/* title */}
      <div>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={titleAnimtaion}
          className="text-center text-primary text-[2rem] lg:text-[3rem]"
        >
          JOIN THE HYPE
        </motion.h2>
      </div>

      {/* Button */}
      <div ref={ref} className="flex justify-center mt-20">
        <motion.button
          initial={{ opacity: 0 }}
          animate={buttonAnimation}
          onClick={() => handleButtonClick()}
          className="px-10 py-4 bg-primary text-white rounded-full"
        >
          Sign up now
        </motion.button>
      </div>
    </div>
  );
};

export default JoinHypeSection;
