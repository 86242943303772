import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TeamLoader = () => {
  return (
    <div className="h-[310px]  w-[350px] bg-white rounded-md mt-[120px] relative">
      <div className="absolute top-0 left-0 w-full flex justify-center -mt-24 p-2">
        <Skeleton style={{ height: "250px", width: "250px" }} circle={true} />
      </div>

      {/* Name */}
      <div className="pt-[170px] w-[150px] mx-auto">
        <Skeleton />
      </div>

      {/* Role */}
      <div className="w-[100px] mx-auto">
        <Skeleton style={{ height: "8px" }} />
      </div>

      {/* Description */}
      <div className="w-[90%] mx-auto">
        <Skeleton count={2} style={{ height: "10px" }} />
      </div>
      <div className="w-[80%] mx-auto">
        <Skeleton style={{ height: "10px" }} />
      </div>
    </div>
  );
};

export default TeamLoader;
