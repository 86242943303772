import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyDYUT_pnTvQsd-2oRqNnb6wNCOvI5tvI2A",
  // authDomain: "volleyball-demo.firebaseapp.com",
  // projectId: "volleyball-demo",
  // storageBucket: "volleyball-demo.appspot.com",
  // messagingSenderId: "612795282523",
  // appId: "1:612795282523:web:2bc1c424b13467d6c37b7e",
  // measurementId: "G-RZ5LXMEVP3",

  apiKey: "AIzaSyDbKnidg1LUKy715yL6kaTi547s2TUxM7c",
  authDomain: "florida-hype-volleyball.firebaseapp.com",
  projectId: "florida-hype-volleyball",
  storageBucket: "florida-hype-volleyball.appspot.com",
  messagingSenderId: "475754242507",
  appId: "1:475754242507:web:6bd399dc499388d786d64e",
  measurementId: "G-2MBK4K5FJQ",
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const db = getFirestore();
const storage = getStorage();

export { app, db, auth, storage };
