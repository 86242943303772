import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const ButtonLoader = () => {
  return (
    <div>
      <ClipLoader color="white" size={20} />
    </div>
  );
};

export default ButtonLoader;
