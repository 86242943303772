import React from "react";
import styles from "./UpComingEventCard.module.css";

const UpComingEventCard = ({ image, name, date }) => {
  return (
    <div className="h-[300px] w-[270px] lg:h-[300px] lg:w-[270px] mx-1 bg-white rounded-bl-lg rounded-t-lg rounded-br-3xl">
      {/* Image */}
      <div className="h-[75%]">
        <img
          className="h-[100%] w-full object-cover rounded-t-lg"
          src={image}
          alt="event"
        />
      </div>
      {/* Bottom Description */}
      <div className="mt-3">
        {/* Title */}
        <div>
          <p className={`text-center ${styles.eventName}`}>{name}</p>
        </div>

        {/* Date */}
        <div className="mt-2 flex justify-center items-center space-x-3">
          <p className={`text-primary ${styles.eventDate}`}>{date}</p>
        </div>
      </div>
    </div>
  );
};

export default UpComingEventCard;
