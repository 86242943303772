import React from "react";
import { motion } from "framer-motion";
import { XIcon } from "@heroicons/react/solid";

const ImagePopUp = ({ popup, layoutId, imageUrl }) => {
  return (
    <div
      onClick={() => popup()}
      className="block fixed top-0 bottom-0 left-0 right-0 bg-[#00000070] z-30"
    >
      <div className="w-[100%] h-[100vh] mx-auto object-contain">
        <motion.img
          className="h-[100vh] w-[100vw] object-contain z-30"
          layoutId={layoutId}
          src={imageUrl}
          alt="popup"
        />
      </div>

      <div className="absolute top-4 right-4">
        <XIcon
          onClick={() => popup()}
          className="h-7 text-white cursor-pointer"
        />
      </div>
    </div>
  );
};

export default ImagePopUp;
