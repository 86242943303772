import React, { useEffect } from "react";
import styles from "./WhyHypePage.module.css";
import OurPartners from "./ourPartners/OurPartners";
import JoinOurClub from "./joinOurClub/JoinOurClub";
import HypeMessage from "./hypeMessage/HypeMessage";
import WhyHypeHeader from "./whyHypeHeader/WhyHypeHeader";
import AdditionalMessageSection from "./additionalMessageSection/AdditionalMessageSection";

const WhyHypePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {/* Header */}
      <WhyHypeHeader />

      {/* Welcome Section */}
      <div className="-mt-8">
        <HypeMessage />
      </div>

      <div className="w-[80%] mt-20 mx-auto max-w-6xl space-y-20">
        <AdditionalMessageSection />
      </div>

      <div className="w-[80%] mt-20 mx-auto max-w-6xl space-y-20">
        {/* Schedule Section */}
        <OurPartners />
      </div>

      {/* Join Our CLub */}
      <div
        className={`mt-20 h-[50vh] lg:h-[50vh] ${styles.joinOurClubSection}`}
      >
        <JoinOurClub />
      </div>
    </div>
  );
};

export default WhyHypePage;
