import { collection, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import styles from "./AdditionalMessageSection.module.css";

const AdditionalMessageSection = () => {
  const [sectinoData, setSectionData] = useState({});

  // Function to get heading data
  useEffect(() => {
    return onSnapshot(
      query(collection(db, "whyHypeAdditionalSection")),
      (snapshot) => {
        setSectionData(snapshot.docs[0]);
      }
    );
  }, [db]);

  return (
    <div className="text-center">
      {/* Header */}
      <div>
        <h3 className="text-primary text-[2rem] lg:text-[2.5rem]">
          {sectinoData.id && sectinoData?.data().title}
        </h3>
      </div>

      {/* Message */}
      <div>
        <p className={styles.secondaryText}>
          {sectinoData.id && sectinoData?.data().subtitle}
        </p>
      </div>
    </div>
  );
};

export default AdditionalMessageSection;
