import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import axios from "axios";
import Carousel from "react-multi-carousel";
import InstagramImage from "./instagramImage/InstagramImage";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const InstagramSection = () => {
  const { ref, inView } = useInView();
  const [instaImageIds, setInstaImageIds] = useState([]);

  const whyHypeAnimation = useAnimation();

  // Function to get instagram data
  const getFeed = async () => {
    await axios
      .get(
        `https://graph.instagram.com/me/media?access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}&fields=media_url,media_type`
      )
      .then((res) => {
        setInstaImageIds(res.data.data);
      });
  };

  useEffect(() => {
    getFeed();
  }, []);

  useEffect(() => {
    if (inView) {
      whyHypeAnimation.start({
        opacity: 1,
        transition: {
          duration: 0.7,
        },
      });
    }
  }, [inView, whyHypeAnimation]);

  return (
    <div>
      <div>
        <h3 className="text-center text-[1.8rem] text-primary lg:text-[2.5rem]">
          Connect with us on Instagram
        </h3>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={whyHypeAnimation}
        ref={ref}
        // className="flex flex-col md:flex-row mt-12"
        className="mt-7"
      >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          draggable={true}
          swipeable={true}
          focusOnSelect={true}
          pauseOnHover={true}
          itemAriaLabel={true}
          className=""
          // arrows={false}
        >
          {instaImageIds.map((data, i) => {
            if (i > 6) return;
            return (
              <div className="md:w-[25vw]">
                <InstagramImage image={data.media_url} type={data.media_type} />
              </div>
            );
          })}
        </Carousel>
      </motion.div>
    </div>
  );
};

export default InstagramSection;
