import React, { useEffect } from "react";
import styles from "./HypeEvents.module.css";

const HypeEvents = ({
  title,
  description,
  index,
  setSelectedEventData,
  image,
}) => {
  const handleFeatureCardClick = () => {
    setSelectedEventData({
      title,
      image,
      description,
      index,
    });
  };

  useEffect(() => {
    if (index === 0) {
      setSelectedEventData({
        title,
        image,
        description,
        index,
      });
    }
  }, [title, image, description, index, setSelectedEventData]);

  return (
    <div
      onClick={() => handleFeatureCardClick()}
      className={`h-full px-4 py-5 cursor-pointer bg-white text-black border rounded-md`}
    >
      {/* Title */}
      <div className="h-[10%]">
        <h3 className={`${styles.header} text-center`}>{title}</h3>
      </div>
      {/* Description */}
      <div className="w-[200px] mx-auto mt-5 h-[90%]">
        <p className={`${styles.description} text-center text-[0.9rem]`}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default HypeEvents;
