import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import styles from "./DirectorsSection.module.css";
import Masonry from "react-masonry-css";
import TeamCard from "../coachesSection/teamCard/TeamCard";
import Fade from "react-reveal/Fade";
import TeamLoader from "../teamLoader/TeamLoader";

const DirectorsSection = () => {
  const [directorsData, setDirectorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loaderArray = Array(3).fill(0);

  const getDirectorData = () => {
    onSnapshot(
      query(collection(db, "director"), orderBy("timestamp", "desc")),
      (snapshot) => {
        setDirectorsData(snapshot.docs);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getDirectorData();
  }, [db]);

  const breakpoints = {
    default: 5,
    2066: 4,
    1607: 3,
    1194: 2,
    737: 1,
  };

  return (
    <div className="w-full">
      <div>
        <h2 className="text-center text-primary text-[2rem] lg:text-[2.5rem]">
          Our Directors
        </h2>
      </div>
      {/* Directors List */}
      {isLoading ? (
        <div className="w-[95%] lg:w-[90%] mx-auto mt-10">
          <Masonry
            breakpointCols={breakpoints}
            className={styles.masnoryGrid}
            columnClassName={styles.masnoryGridColumn}
          >
            {loaderArray.map((data, i) => {
              return (
                <div className="w-full ml-1.5 flex justify-center" key={i}>
                  <Fade bottom>
                    <TeamLoader key={i} />
                  </Fade>
                </div>
              );
            })}
          </Masonry>
        </div>
      ) : (
        <div className="w-[95%] lg:w-[90%] mx-auto mt-10 flex flex-wrap justify-center space-x-5">
          <div className="grid sm:grid-cols-2 sm:gap-x-3 xl:grid-cols-3 2xl:grid-cols-4 2xl:gap-x-8">
            {directorsData.map((coachData, i) => {
              return (
                <div className="w-full ml-1.5 flex justify-center" key={i}>
                  <TeamCard
                    type="director"
                    name={coachData.data().name}
                    image={coachData.data().image}
                    role={coachData.data().role}
                    description={coachData.data().description}
                    id={coachData.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DirectorsSection;
