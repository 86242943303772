import React, { useEffect } from "react";
import HelpingOthersBoxImages from "./helpingOthersBoxImages/HelpingOthersBoxImages";
import HelpingOthersHeroImage from "./HelpingOthersHeroImage/HelpingOthersHeroImage";
import HelpingOthersPosters from "./helpingOthersPosters/HelpingOthersPosters";

const HelpingOthersPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {/* Hero Image */}
      <div>
        <HelpingOthersHeroImage />
      </div>

      <div className="w-[80%] mx-auto max-w-6xl">
        <HelpingOthersBoxImages />
      </div>

      <div className="w-[80%] mx-auto max-w-6xl mt-20">
        <HelpingOthersPosters />
      </div>
    </div>
  );
};

export default HelpingOthersPage;
