import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import React, { useEffect, useState } from "react";
import ImagePopUp from "../../../components/imagePopUp/ImagePopUp";
import { db } from "../../../firebase";
import HelpingOtherPosterImage from "./helpingOtherPosterImage/HelpingOtherPosterImage";
import styles from "./HelpingOthersPosters.module.css";

const HelpingOthersPosters = () => {
  const [imagePopUp, setImagePopUp] = useState(false);
  const [layoutId, setLayoutId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [posters, setPosters] = useState([]);

  // FUnction to get posters data
  const getPosters = () => {};

  useEffect(() => {
    return onSnapshot(
      query(
        collection(db, "helpingOthersPosterImage"),
        orderBy("timestamp", "desc")
      ),
      (snapshot) => {
        setPosters(snapshot.docs);
      }
    );
  }, []);

  const popup = (id, image) => {
    if (!imagePopUp) {
      setImagePopUp(true);
      setLayoutId(id);
      setImageUrl(image);
    } else {
      setLayoutId(null);
      setImagePopUp(false);
      setImageUrl(null);
    }
  };

  return (
    <div>
      <AnimateSharedLayout>
        {/* Posters */}
        {posters.map((image, i) => {
          return (
            <HelpingOtherPosterImage
              key={i}
              popup={popup}
              image={image.data().poster}
              id={image.id}
              title={image.data().title ? image.data().title : ""}
              subtitle={image.data().subtitle ? image.data().subtitle : ""}
            />
          );
        })}

        {/* Modal */}
        <div>
          <AnimatePresence>
            {imagePopUp && (
              <ImagePopUp
                popup={popup}
                imageUrl={imageUrl}
                layoutId={layoutId}
              />
            )}
          </AnimatePresence>
        </div>
      </AnimateSharedLayout>
    </div>
  );
};

export default HelpingOthersPosters;
