import React from "react";
import { motion } from "framer-motion";
import styles from "./HelpingOtherPosterImage.module.css";

const HelpingOtherPosterImage = ({ image, popup, id, title, subtitle }) => {
  return (
    <div className="flex flex-col justify-center mt-20 cursor-pointer">
      {/* Header */}
      <div className="mb-5">
        {/* Title */}
        <div>
          <h4 className="text-primary text-[2rem] text-center">{title}</h4>
        </div>

        {/* Subtitle */}
        <div>
          <p className={`text-center ${styles.secondaryText}`}>{subtitle}</p>
        </div>
      </div>

      <motion.img
        onClick={() => popup(id, image)}
        src={image}
        className="object-contain h-auto w-[95vw] md:w-[90vw] lg:w-auto lg:h-[85vh] mx-auto cursor-pointer"
        loading="lazy"
      />
    </div>
  );
};

export default HelpingOtherPosterImage;
