import React from "react";
import ReactPlayer from "react-player";

const InstagramImage = ({ image, type }) => {
  const redirectToInstagram = () => {
    window.open("https://www.instagram.com/florida_hype_volleyball/");
  };

  return (
    <div
      onClick={() => redirectToInstagram()}
      className="[250px] w-full relative"
    >
      {type === "VIDEO" ? (
        <div className="flex justify-center items-center">
          <ReactPlayer
            width="100%"
            height="200px"
            url={image}
            facebook={true}
            controls={true}
          />
        </div>
      ) : (
        <div>
          <img className="h-[200px] w-full object-cover" src={image} />
        </div>
      )}
    </div>
  );
};

export default InstagramImage;
