import { ArrowLeftIcon, MailIcon } from "@heroicons/react/solid";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import styles from "./SingleDirectorPage.module.css";

const SingleDirectorPage = () => {
  const topRef = useRef();
  const { id } = useParams();
  const [directorData, setDirectorData] = useState({});

  const getData = async () => {
    const docRef = doc(db, "director", id);
    const data = await getDoc(docRef);

    setDirectorData(data.data());
  };

  useEffect(() => {
    getData();
  }, []);

  const handleBackClick = () => {
    window.history.go(-1);
  };

  return (
    <div>
      <div ref={topRef} className="min-h-[50vh] ">
        <div className="flex flex-col lg:flex-row px-10 lg:px-0 justify-center mt-16 lg:space-x-10">
          {/* Back Button Section */}
          <div className="lg:hidden">
            {directorData.description && (
              <div
                onClick={() => handleBackClick()}
                className="flex items-center space-x-2 text-gray-700 mb-5"
              >
                <ArrowLeftIcon className="h-7" /> <p>Back</p>
              </div>
            )}
          </div>

          {/* Image */}
          <div>
            <img
              className="w-[90vw] lg:w-auto  md:h-[550px] object-contain"
              src={directorData?.image}
              alt={directorData?.name}
            />
          </div>

          {/* Name and Description */}
          <div className="mt-5 md:mt-0 text-center md:text-start">
            {/* Name */}
            <div>
              <h4 className="text-[3rem] text-primary ">{directorData.name}</h4>
            </div>

            {/* Descriptoin */}
            <div className="lg:max-w-[50vw] mt-5 ">
              <p className={styles.secondaryText}>{directorData.description}</p>
            </div>

            {/* Email */}
            {directorData.email && (
              <div className="flex items-center justify-center mt-5 cursor-pointer">
                <a
                  href={`mailto:${directorData.email}`}
                  className="flex bg-primary px-7 py-2 rounded-full"
                >
                  <MailIcon className="text-white h-7" />
                  <p className={`ml-2 text-white ${styles.secondaryText}`}>
                    {directorData.email}
                  </p>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDirectorPage;
