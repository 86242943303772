import React, { useEffect, useState } from "react";
import styles from "./PaymentHeader.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";

const PaymentHeader = () => {
  const { ref, inView } = useInView();
  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const [headerData, setHeaderData] = useState({});

  // Function to get heading data
  useEffect(() => {
    return onSnapshot(query(collection(db, "whyHypeHeading")), (snapshot) => {
      setHeaderData(snapshot.docs[0]);
    });
  }, [db]);

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        opacity: 1,
        transition: { duration: 1 },
      });

      descriptionAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 0.5 },
      });
    }
  }, [inView, titleAnimation, descriptionAnimation]);

  return (
    <div>
      <div
        className={`w-[100vw] h-[80vh] relative ${styles.headerImageContainer}`}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-[#000000ae] px-20">
          <div className="flex items-center flex-col justify-center h-full w-[100%] ml-auto">
            {/* Heading  */}
            <div className="">
              <motion.h2
                initial={{ opacity: 0 }}
                animate={titleAnimation}
                className="text-[2rem] text-center  lg:text-[3rem] text-white"
              >
                {headerData.id && headerData?.data().title}
              </motion.h2>
            </div>

            {/* Description */}
            <div className="w-full" ref={ref}>
              <motion.p
                initial={{ opacity: 0 }}
                animate={descriptionAnimation}
                className={`text-white text-center mt-5 w-full  ${styles.description}`}
              >
                {headerData.id && headerData?.data().subtitle}
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHeader;
