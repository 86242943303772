import React from "react";
import styles from "./PartnerDescription.module.css";

const PartnerDescription = ({ name, image, description, link }) => {
  const openLink = () => {
    window.open(link);
  };
  return (
    <div
      onClick={() => openLink()}
      className="bg-white rounded-sm shadow-md w-[95%] lg:w-[400px] flex flex-col items-center px-5 py-7"
    >
      <img className="h-[100px] object-contain" src={image} alt={name} />

      {/* Name */}
      <div className="mt-5">
        <h3 className="text-center text-primary">{name}</h3>
      </div>

      {/* Description */}
      <div className="mt-3">
        <p className={`text-[0.7rem] text-center ${styles.description}`}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default PartnerDescription;
