import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./DonationSection.module.css";
import { useAnimation, motion } from "framer-motion";

const DonationSection = () => {
  const { ref, inView } = useInView();
  const imageAnimation = useAnimation();
  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const buttonAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      imageAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7 },
      });

      titleAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7 },
      });

      descriptionAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7, delay: 0.3 },
      });

      buttonAnimation.start({
        y: "0%",
        opacity: 1,
        transition: { duration: 0.7, delay: 0.6 },
      });
    }
  }, [inView]);

  // Functin to handle donation click
  const handleDonationClick = () => {
    window.open("https://buy.stripe.com/4gwdTA2Fp8gl5X2bIL");
  };

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-16">
      <div className="absolute top-0 left-0 h-full w-full bg-[#000000be]">
        <div className="flex items-center justify-center h-full w-[80%] mx-auto">
          {/* Description */}
          <div className="flex flex-col flex-1 mt-3 lg:mt-0">
            {/* Title */}
            <div>
              <motion.p
                initial={{ opacity: 0, y: "100%" }}
                animate={titleAnimation}
                className={`text-primary text-center text-[2.5rem] ${styles.title}`}
              >
                THANKS TO OUR PARTNERS
              </motion.p>
            </div>

            {/* Desctiption */}
            <div className="mt-5">
              <motion.p
                ref={ref}
                initial={{ opacity: 0, y: "100%" }}
                animate={descriptionAnimation}
                className={`text-white text[center]  ${styles.description}`}
              >
                As a sponsor, your financial contribution is vital to our
                mission. We truly appreciate your interest in helping our
                organization thrive. You can rest assured that your generous
                donation is being put to good use. YOUR PARTNERShip gives a
                young girl an opportunity to be fiercely competitive and grow
                not only in the court but develop the necessary skills and
                discipline to be successful in life.
              </motion.p>
            </div>

            {/* Button */}
            <div className="mt-10 flex justify-center">
              <motion.button
                onClick={() => handleDonationClick()}
                initial={{ opacity: 0, y: "100%" }}
                animate={buttonAnimation}
                className="text-white bg-primary px-10 py-3 rounded-full"
              >
                DONATE NOW
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationSection;
