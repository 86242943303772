import React, { useState } from "react";
import styles from "./JoinOurClub.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import JoinHypeModal from "../../../components/joinHypeModal/JoinHypeModal";

const JoinOurClub = () => {
  const { ref, inView } = useInView();
  const [openJoinModal, setOpenJoinModal] = useState(false);

  // Function to close joni modal
  const closeJoinModal = () => setOpenJoinModal(false);

  const headerAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const buttonAnimtaion = useAnimation();

  useEffect(() => {
    if (inView) {
      headerAnimation.start({
        opacity: 1,
        transition: {
          duration: 0.6,
        },
      });

      descriptionAnimation.start({
        opacity: 1,
        transition: {
          duration: 0.6,
          delay: 0.3,
        },
      });

      buttonAnimtaion.start({
        opacity: 1,
        transition: {
          duration: 0.7,
          delay: 0.6,
        },
      });
    }
  }, [inView, headerAnimation, descriptionAnimation, buttonAnimtaion]);

  return (
    <div className="relative h-full w-full">
      <div className="absolute top-0 left-0 bg-[#000000a3] h-full w-full">
        <div className="flex flex-col items-center justify-center h-full">
          {/* Heading */}
          <motion.h3
            initial={{ opacity: 0 }}
            animate={headerAnimation}
            className="text-primary text-[2rem] lg:text-[2.5rem] text-center"
          >
            JOIN OUR CLUB
          </motion.h3>

          {/* Description */}
          <div ref={ref}>
            <motion.p
              initial={{ opacity: 0 }}
              animate={descriptionAnimation}
              className={`text-white text-[0.8rem] tracking-wider ${styles.description}`}
            >
              We are glad to welcome you to our volleyball family
            </motion.p>
          </div>

          {/* JOin Button */}
          <div>
            <motion.button
              initial={{ opacity: 0 }}
              animate={buttonAnimtaion}
              onClick={() => setOpenJoinModal(true)}
              className="bg-primary px-8 py-3 rounded-full text-white mt-7"
            >
              JOIN US
            </motion.button>
          </div>
        </div>
      </div>

      {/* Join Form Modal */}
      <JoinHypeModal
        modalState={openJoinModal}
        closeJoinModal={closeJoinModal}
        from="whyHypeJoinUs"
      />
    </div>
  );
};

export default JoinOurClub;
