import Router from "./router/Router";
import { BrowserRouter } from "react-router-dom";

function App() {
  // Function to handle social media route
  const handleRoute = (link) => {
    window.open(link);
  };
  return (
    <div className="min-h-[100vh] bg-gray-200 relative">
      <BrowserRouter>
        <Router />
      </BrowserRouter>

      <div className="fixed bottom-8 right-7">
        {/* FaceBook */}
        <div
          onClick={() =>
            handleRoute(
              "https://www.facebook.com/profile.php?id=100071961623626"
            )
          }
          className="widgitIcons"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#4b9cd3"
            stroke="#4b9cd3"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-facebook"
          >
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
          </svg>
        </div>

        {/* Instagram */}
        <div
          onClick={() =>
            handleRoute("https://www.instagram.com/florida_hype_volleyball/")
          }
          className="widgitIcons mt-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#4b9cd3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-instagram"
          >
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default App;
