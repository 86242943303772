import { MenuIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";
import { XIcon } from "@heroicons/react/solid";
import { Link, useLocation } from "react-router-dom";

const HeaderBurgerMenu = () => {
  const [showCanvas, setShowCanvas] = useState(false);
  const { pathname } = useLocation();

  // Function to close off canvas
  const closeOffCanvas = () => {
    setShowCanvas(false);
  };

  const links = [
    {
      name: "Home",
      link: "/",
    },
    { name: "Why HYPE", link: "/why-HYPE" },
    { name: "The HYPE Team", link: "/hype-team" },
    { name: "2023/2024", link: "/2023-2024" },
    { name: "Helping Others", link: "/helping-others" },
    { name: "Contact Us", link: "/contact-us" },
  ];

  return (
    <div className="bg-white bg-gradient-to-t from-[#F0EFF1] py-4 px-4 shadow-md">
      {/* OffCanvas */}
      <div>
        <OffCanvas
          width={300}
          transitionDuration={300}
          isMenuOpened={showCanvas}
          position={"left"}
          effect={"overlay"}
        >
          <OffCanvasBody>
            <div>
              <MenuIcon
                onClick={() => setShowCanvas(true)}
                className="h-7 pl-2"
              />
            </div>
          </OffCanvasBody>

          <OffCanvasMenu>
            <div className="bg-white w-[70vw] h-[100vh] ">
              {/* Cross Icon */}
              <div
                onClick={() => closeOffCanvas()}
                className="absolute right-12 top-2"
              >
                <XIcon className="h-5 text-black" />
              </div>

              {/* Header  */}
              <div className="pt-2 px-5">
                <h5 className="text-[1.3rem]">Menu</h5>
              </div>

              {/* Options */}
              <div className="px-5 py-8 flex flex-col space-y-3">
                {links.map((link, i) => {
                  return (
                    <Link
                      key={i}
                      onClick={() => closeOffCanvas()}
                      to={link.link}
                      className={`${pathname === link.link && "text-primary"}`}
                    >
                      {link.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </OffCanvasMenu>
        </OffCanvas>
      </div>
    </div>
  );
};

export default HeaderBurgerMenu;
