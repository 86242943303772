import React from "react";
import styles from "./ClubDetails.module.css";

const ClubDetails = () => {
  const email = "leo.hypevolleyballclub@gmail.com";
  const phone1 = "+1 (813) 352-1742";
  const phone2 = "+1 (813) 495-0530";
  return (
    <div className="w-full">
      {/* Phone */}
      <div className="mt-5">
        <h4 className="clubDetailsHeader">PHONE</h4>
        {/* Details */}
        <div className="mt-2 flex flex-col">
          <a
            href={`tel:${phone1}`}
            className={`hover:text-primary ${styles.secondaryText}`}
          >
            {phone1}
          </a>
          <a
            href={`tel:${phone2}`}
            className={`hover:text-primary ${styles.secondaryText}`}
          >
            {phone2}
          </a>
        </div>
      </div>

      {/* Working Hours */}
      <div className="mt-5">
        <h4 className="clubDetailsHeader">Email</h4>
        {/* Details */}
        <div className="mt-2">
          <a
            href={`mailto:${email}`}
            className={`cursor-pointer hover:text-primary ${styles.secondaryText}`}
          >
            {email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ClubDetails;
