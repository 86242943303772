import React, { useEffect } from "react";

const documents = [
  {
    name: "Hype Financial",
    address: "./documents/Hype_Financial.pdf",
    image: "/documents/hype_financial.png",
  },
  {
    name: "Liability Wavier Hype",
    address: "./documents/Liability_Wavier_Hype.pdf",
    image: "/documents/liability.png",
  },
  {
    name: "Parent Code of Conduct",
    address: "./documents/Parent_Code_of_Conduct.pdf",
    image: "/documents/parent_code_of_conduct.png",
  },
  {
    name: "Player Code of Conduct",
    address: "./documents/Player_Code_of_Conduct.pdf",
    image: "/documents/player_code_of_conduct.png",
  },
  {
    name: "Sponsorship Form",
    address: "./documents/sponsorship_form.pdf",
    image: "/documents/sponsorship_request.png",
  },
];

const DocumentsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pt-10 gap-y-10">
      {documents.map((doc, i) => {
        return (
          <div>
            {/* Image */}
            <div className="flex justify-center">
              <img
                className="h-72 w-72 object-contain"
                src={doc.image}
                alt={doc.name}
              />
            </div>
            {/* Form Name */}
            <div className="text-center mt-4">
              <p>{doc.name}</p>
            </div>
            {/* Download Button */}
            <div className="mt-3 flex justify-center">
              <a href={require(`${doc.address}`)} download={doc.name}>
                <button className="text-white bg-primary px-7 py-2 rounded-full">
                  Download
                </button>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DocumentsPage;
