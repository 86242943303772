import React from "react";
import { motion } from "framer-motion";
import styles from "./TwentyThreePosterCard.module.css";

const TwentyThreePosterCard = ({ image, popup, id, title, subtitle }) => {
  return (
    <div className="flex flex-col justify-center mt-20">
      {/* Text */}
      <div className="text-center mb-4">
        {/* Title */}
        <h3 className="text-primary text-[2rem]">{title}</h3>

        {/* Subtitle */}
        <p className={`${styles.secondaryText}`}>{subtitle}</p>
      </div>
      <motion.img
        onClick={() => popup(id, image)}
        src={image}
        className="object-contain h-auto w-[95vw] md:w-[90vw] lg:w-auto lg:h-[85vh] mx-auto cursor-pointer"
      />
    </div>
  );
};

export default TwentyThreePosterCard;
