import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HypeEvents from "./hypeEvents/HypeEvents";
import styles from "./HomeHeader.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const HomeHeader = () => {
  const { ref, inView } = useInView();
  const [selectedEventData, setSelectedEventData] = useState({});
  const [homePageData, setHomePageData] = useState([]);
  const titleAnimation = useAnimation();
  const descriptionAnimation = useAnimation();
  const buttonAnimation = useAnimation();
  const logoAnimation = useAnimation();

  // Function to get hype features data
  useEffect(() => {
    return onSnapshot(query(collection(db, "homeTitle")), (snapshot) => {
      setHomePageData(snapshot.docs);
    });
  }, [db]);

  useEffect(() => {
    if (inView) {
      titleAnimation.start({
        opacity: 1,
        transition: { duration: 1 },
      });

      descriptionAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 0.5 },
      });

      buttonAnimation.start({
        opacity: 1,
        transition: { duration: 1, delay: 1 },
      });

      logoAnimation.start({
        opacity: 1,
        transition: { duration: 1 },
      });
    }
  }, [
    inView,
    selectedEventData,
    titleAnimation,
    descriptionAnimation,
    logoAnimation,
  ]);

  return (
    <div>
      {/* Image  */}
      <div
        className={`w-full h-[50vh] lg:h-[70vh] relative ${styles.homeImageContainer}`}
      >
        <div className="absolute top-0 h-full w-full">
          <div className="flex flex-col items-center justify-center h-full text-white">
            <div ref={ref}>
              <motion.img
                initial={{ opacity: 0 }}
                animate={logoAnimation}
                className="h-[75vw] md:h-[60vw] lg:h-[500px] mx-auto"
                alt="HYPE logo"
                src="/logo/logo.png"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Events List */}
      <div className="bg-white -mt-10 z-20 w-[95%] mx-auto rounded-lg">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          draggable={true}
          swipeable={true}
          focusOnSelect={true}
          pauseOnHover={true}
          itemAriaLabel={true}
        >
          {homePageData.map((product, i) => {
            return (
              <HypeEvents
                key={i}
                title={product.data().title}
                description={product.data().description}
                allData={product}
                index={i}
                selectedEventData={selectedEventData}
                setSelectedEventData={setSelectedEventData}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default HomeHeader;
